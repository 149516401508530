
import { Component, Vue } from 'vue-property-decorator'
import { Table, Info } from '@/types/manholeException'
import { ElForm } from 'element-ui/types/form'
import { timeCompletion } from '@/utils/formatData'

@Component({
  filters: {
    thisStateFilters (value: string) {
      return value === '0' ? '未生效' : value === '1' ? '生效中' : value === '2' ? '已失效' : ''
    },
    tiemFilters (value: string) {
      return timeCompletion(value)
    }
  }
})
export default class List extends Vue {
  private searchInfo = {
    projectId: '',
    areaId: '',
    manholeName: '',
    thisState: ''
  }

  private dataRange= []

  private loading = false
  private tableData: Table[] = []
  private areaList = []
  private addAreaList = []
  private manholeList = []

  private page = 1
  private size = 10
  private total = 0
  private visibleShow = false
  private submitShow = false
  private title = '新增告警例外'
  private info: Info= {
    exceptionId: '',
    projectId: '',
    areaId: '',
    dataRange: [],
    manholeIdList: []
  }

  private rules = {
    projectId: [
      { required: true, message: '请选择项目', trigger: 'change' }
    ],
    areaId: [
      { required: true, message: '请选择区域', trigger: 'change' }
    ],
    manholeIdList: [
      { required: true, message: '请选择井盖', trigger: ['change', 'blur'] }
    ],
    dataRange: [
      { type: 'array', required: true, message: '请选择时间', trigger: 'change' }
    ]
  }

  get projectList () {
    return this.$store.state.projectList
  }

  created (): void {
    this.getList()
  }

  // 查询
  onQuery () {
    this.page = 1
    this.getList()
  }

  // 表格
  getList () {
    this.loading = true
    const info = this.dataRange ? {
      startTime: this.dataRange[0],
      endTime: this.dataRange[1],
      ...this.searchInfo
    } : {
      ...this.searchInfo
    }
    this.$axios
      .get<{ total: number; list: Table[] }>(
        this.$apis.manholeException.selectManholeExceptionByPage,
        {
          page: this.page,
          size: this.size,
          ...info
        }
      )
      .then((res) => {
        if (res) {
          this.tableData = res.list || []
          this.total = res.total || 0
        }
      })
      .finally(() => {
        this.loading = false
      })
  }

  getAreaList (projectId: string) {
    this.$axios.get(this.$apis.project.selectProjectAreaByList, {
      projectId,
      notAllArea: '1'
    }).then(res => {
      if (this.visibleShow) {
        this.addAreaList = res.list || []
      } else {
        this.areaList = res.list || []
      }
    })
  }

  getManholeList (id: string) {
    this.$axios.get(this.$apis.manholeException.selectManholeByList, {
      areaId: id
    }).then(res => {
      this.manholeList = res.list || []
    })
  }

  // 全部选中
  allChange (arrList: string[]) {
    if (arrList.length > 0 && arrList[arrList.length - 1] === 'all') {
      this.info.manholeIdList = []
      this.manholeList.map((item: Table) => {
        this.info.manholeIdList.push(item.manholeId)
      })
    }
  }

  projectChange (projectId: string) {
    if (this.visibleShow) {
      this.info.areaId = ''
      this.addAreaList = []
    } else {
      this.searchInfo.areaId = ''
      this.areaList = []
    }
    if (projectId) {
      this.getAreaList(projectId)
    }
  }

  areaChange (id: string) {
    this.info.manholeIdList = []
    this.areaList = []
    if (id) {
      this.getManholeList(id)
    }
  }

  onAdd () {
    this.title = '新增告警例外'
    this.visibleShow = true
  }

  onUpdate (exceptionId: string) {
    this.title = '编辑告警例外'
    this.visibleShow = true
    this.$axios.get(this.$apis.manholeException.selectManholeExceptionByExceptionId, {
      exceptionId
    }).then(res => {
      this.info = {
        exceptionId: res.exceptionId,
        projectId: res.projectId,
        areaId: res.areaId,
        dataRange: [timeCompletion(res.startTime), timeCompletion(res.endTime)],
        manholeIdList: res.manholeIdList
      }
      this.getAreaList(res.projectId)
      this.getManholeList(res.areaId)
    })
  }

  // 取消
  onCancel (exceptionId: string) {
    this.$confirm('确认取消吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.manholeException.cancelManholeException, { exceptionId }).then(() => {
        this.$message.success('取消成功')
        this.onQuery()
      })
    })
  }

  // 弹窗关闭
  closeDialog () {
    this.info = {
      exceptionId: '',
      projectId: '',
      areaId: '',
      dataRange: [],
      manholeIdList: []
    }
    this.$nextTick(() => {
      (this.$refs.info as ElForm).clearValidate()
    })
    this.visibleShow = false
  }

  onSubmit () {
    (this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        this.submitShow = true
        const info = this.info.exceptionId ? {
          exceptionId: this.info.exceptionId,
          manholeIdList: this.info.manholeIdList,
          startTime: this.info.dataRange[0],
          endTime: this.info.dataRange[1],
          projectId: this.info.projectId,
          areaId: this.info.areaId
        } : {
          manholeIdList: this.info.manholeIdList,
          startTime: this.info.dataRange[0],
          endTime: this.info.dataRange[1],
          projectId: this.info.projectId,
          areaId: this.info.areaId
        }
        const url = this.info.exceptionId ? this.$apis.manholeException.updateManholeException : this.$apis.manholeException.insertManholeException
        this.$axios.post(url, info).then(() => {
          this.$message.success('保存成功')
          this.closeDialog()
          this.onQuery()
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }
}
